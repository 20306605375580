<template>
  <div class="app">
    <toubu></toubu>
    <!-- <div
      style="
        height: 69px;
        padding: 0 0%;
        background-color: #fff;
        justify-content: flex-start;
      "
      class="erqiwu"
    >
       <img
        style="height: 60px"
        src="@/assets/imges/90fe6e3a48949d2873e211e050690f1.png"
        alt=""
      />
    </div> -->
    <div style="" class="app-a">
      <BDhear :num="6"></BDhear>
    </div>

    <div class="quan">
      <div v-if="!yixia" style="position: relative;" class="">
        <div v-if="ie9">
          <img class="asimg" src="@/assets/he.gif" alt="" srcset="" />
        </div>
        <div style="" v-if="ie9" class="addxin addxinss">
          {{ qianyue }}
        </div>
        <div v-if="!ie9" style="position: relative;" class="sk-circle">
          <div v-if="!ie9" class="sk-circle1 sk-child"></div>
          <div v-if="!ie9" class="sk-circle2 sk-child"></div>
          <div v-if="!ie9" class="sk-circle3 sk-child"></div>
          <div v-if="!ie9" class="sk-circle4 sk-child"></div>
          <div v-if="!ie9" class="sk-circle5 sk-child"></div>
          <div v-if="!ie9" class="sk-circle6 sk-child"></div>
          <div v-if="!ie9" class="sk-circle7 sk-child"></div>
          <div v-if="!ie9" class="sk-circle8 sk-child"></div>
          <div v-if="!ie9" class="sk-circle9 sk-child"></div>
          <div v-if="!ie9" class="sk-circle10 sk-child"></div>
          <div v-if="!ie9" class="sk-circle11 sk-child"></div>
          <div v-if="!ie9" class="sk-circle12 sk-child"></div>
          <div class="addxin">
            {{ qianyue }}
          </div>
        </div>
      </div>
      <!-- <div v-if="!yixia" style="position: relative" class="sk-circle">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
        <div
          style="
         
          "
          class="addxin"
        >
          {{ qianyue }}
        </div>
      </div> -->
      <div v-else class="addxin3" style="">
        <img
          style="width: 296px; height: 150.29px;"
          src="../../assets/login/fafang.png"
          alt=""
        />
      </div>
      <div v-if="false" class="felx">
        <div class="cion-fe">
          <div class="cdion-txt">保函签章</div>
          <div :class="ban ? 'cdion-2' : 'cdion-1'">
            <!-- <div class="cdion-2">  <div class="cdion-3">3</div></div> -->
            <!-- <i v-if="ban" class="el-icon-s-check"></i>
                 <i v-else class="yanse el-icon-check"></i> -->
            <img
              v-if="ban"
              src="../../../src/assets/imges/jiazai.gif"
              class="yanse1"
              alt=""
              srcset=""
            />
            <!-- <img v-else src="../../../src/assets/imges/icu3.png" class="a-imgs" alt="" srcset=""> -->
            <i v-else class="yanse el-icon-check"></i>
          </div>
          <div class="wohi-txt" v-if="ban">(加载中...)</div>
          <div class="wohi-txt" v-else></div>
        </div>
        <div v-if="bai" class="felx-1">
          <div>
            {{ duan }}
          </div>
          <!-- <div class="dion">▶</div> -->
          <!-- <div>
                 {{duan}}
            </div> -->
        </div>

        <div v-else class="felx-2">
          <div>
            {{ duanl }}
          </div>
          <!-- <div class="dion">▶</div> -->
          <!-- <div>
                 {{duanl}}
            </div> -->
        </div>
        <div class="cion-fe">
          <div class="cdion-txt">保函下载</div>
          <div :class="bai ? 'cdion-2' : 'cdion-1'">
            <!-- <div class="cdion-2">  <div class="cdion-3">4</div></div> -->
            <!-- <i v-if="bai" class="yanse el-icon-refresh"></i> -->
            <!-- <img v-else src="../../../src/assets/imges/icu4.png" class="a-imgs" alt="" srcset=""> -->
            <img
              v-if="bai"
              src="../../../src/assets/imges/jiazai.gif"
              class="yanse1"
              alt=""
              srcset=""
            />
            <i v-else class="yanse el-icon-check"></i>
          </div>
          <div class="wohi-txt" v-if="bai">(加载中...)</div>
          <div class="wohi-txt" v-else></div>
        </div>
      </div>
      <div v-if="!tong" style="" class="addxin4">
        <el-button class="asdabutton" type="primary" @click="project">
          {{ tongbu }}
        </el-button>
        <!--  <div v-else class="cion-bu1"></div> -->
      </div>
    </div>
    <!-- <div class="asdzong">
  <div style="    padding: 38px; border-bottom: 3px #efefef solid;"><img src="../../assets/login/lianxiwomen.png" class="asdimgh" alt="" srcset=""></div>
  <div style="margin-top: 20px; display:flex; flex-direction:column; align-items: center;"><div><img src="../../assets/login/tang.png" class="asdimgh" alt="" srcset=""></div>
  <div style="font-size: 14px;">业务联系人：唐先生</div>
   <div style="font-size: 14px;">联系电话：13140161000</div>
  </div>
   <div style="margin-top: 20px; display:flex; flex-direction:column; align-items: center;"><div><img src="../../assets/login/zhang.png" class="asdimgh" alt="" srcset=""></div>
  <div style="font-size: 14px;">技术联系人：张先生</div>
   <div style="font-size: 14px;">联系电话：13838271800</div>
  </div>
</div> -->
    <div v-if="false" class="asdding">
      <div style="background: #fff;" class="asdzongs">
        <div style="margin-top: 5px;">联系我们</div>
        <!-- <div style="    padding: 38px; border-bottom: 3px #efefef solid;"><img src="../../assets/login/lianxiwomen.png" class="asdimgh" alt="" srcset=""></div> -->
        <div style="" class="addxin2">
          <div @mouseover="chuxian(1)" @mouseout="yincang(1)">
            <img
              src="../../assets/login/tang.png"
              style="width: 60px; height: 60px;"
              class="asdimgh"
              alt=""
              srcset=""
            />
          </div>
          <div style="font-size: 14px;">业务联系人：唐先生</div>
          <div style="font-size: 14px;">联系电话：13140161000</div>
        </div>
        <div style="" class="addxin1">
          <div @mouseover="chuxian(2)" @mouseout="yincang(2)">
            <img
              src="../../assets/login/zhang.png"
              style="width: 60px; height: 60px;"
              class="asdimgh"
              alt=""
              srcset=""
            />
          </div>
          <div style="font-size: 14px;">技术联系人：张先生</div>
          <div style="font-size: 14px;">联系电话：13838271800</div>
        </div>
        <div v-if="tang" style="position: absolute; right: 100%;" class="block">
          <img style="width: 300px;" src="../../assets/login/tang1.png" />
        </div>
        <div
          v-if="zhang"
          style="position: absolute; right: 100%;"
          class="block"
        >
          <img style="width: 300px;" src="../../assets/login/zhang1.png" />
        </div>
      </div>
    </div>

    <dinad style="width: 100%; position: absolute; bottom: 0px;"></dinad>
  </div>
</template>

<script>
import toubu from '../../components/toubu.vue'
import { bhtypelist, bhprocess, baohannotice, bhfileState } from '@/api/user'
import { createcontract, filesign, bhfiledown } from '@/api/user'
import BDhear from '../../components/BDhear.vue'
import dinad from '../../components/dinad.vue'
export default {
  components: {
    dinad,
    BDhear,
    toubu
  },
  data() {
    return {
      input: {
        baohanno: '',
        unify_code: '',
        zfimage: '',
        bankname: '',
        bankcode: ''
      },
      yixia: false,
      yi: true,
      kai: true,
      ban: true,
      bai: true,
      quan: false,
      qianyue: '出函中',
      duan: '- - - - -',
      duanl: '———',
      active: 0,
      bankaccount: '',
      tong: true,
      tongbu: '同步资料完成，跳转下载',
      tabPosition: 'right',
      tab: 'left',
      dialogImageUrl: '',
      cion: '',
      dialogVisible: false,
      unify_code: '',
      applyno: '',
      codeion: 0,
      message: '',
      tang: false,
      zhang: false,
      ie9: false,
      imgName: '../../assets/login/tang1.png'
    }
  },
  async created() {
    var that = this
    var userAgent = navigator.userAgent // 获取浏览器信息
    var IEReg = new RegExp('MSIE \\d+\\.\\d+;') // 正则校验IE标识符
    var IEMsg = IEReg.exec(userAgent) // 获取字符串
    var IEVersionNum = new RegExp('\\d+\\.\\d') // 正则获取版本

    // 是IE9，引入js文件
    if (IEMsg && parseFloat(IEVersionNum.exec(IEMsg)) == 9) {
      this.ie9 = true
      console.log('IE9引入')
    } else {
      //  import('@/config/index.css')
      console.log('非IE9不引入')
    }
    // return
    this.applyno = sessionStorage.getItem('applyno')
    this.input.baohanno = sessionStorage.getItem('baohanno')
    // return
    // var apps = "TBaoHanHNYZTBBH2022072717222050537";
    var bbc = this.applyno
    const { data } = await bhprocess(bbc) //判断是否支付
    console.log('页面第一次', data)
    if (data.code !== 200) {
      return
    }
    this.state = data.data.status
    //判断是否支付  书豪测试
    if (this.state < 2) {
      //  return;
      this.$router.push({
        name: 'project',
        params: {}
      })
      return
    }
    if (this.state == 2) {
      console.log('已支付')
      if (data.data.filepath == null || data.data.filepath == '') {
        console.log('没文件')
        this.entryUserInfo() //合同签章
      } else {
        console.log('已经支付')
        setTimeout(() => {
          that.tongbu = '同步资料完成，跳转下载'
          that.tong = false
        }, 1000)
        this.yixia = true
        // this.ajsd(); //已经支付  判断有无文件
      }
    } else {
      if (data.data.filepath == null || data.data.filepath == '') {
        console.log('没文件')
        this.entryUserInfo()
      } else {
        console.log('已有文件判断下载状态')
        setTimeout(() => {
          that.tongbu = '同步资料完成，跳转下载'
          that.tong = false
        }, 1000)
        this.yixia = true
        // this.ajsd(); //已经支付  判断有无文件
      }
    }
  },
  methods: {
    chuxian(e) {
      if (e == 1) {
        this.tang = true
      } else {
        this.zhang = true
      }
    },
    yincang(e) {
      if (e == 1) {
        this.tang = false
      } else {
        this.zhang = false
      }
    },
    async ajsd() {
      //正在使用
      var apps = this.applyno
      var that = this
      const { data } = await bhfileState(apps) //获取状态值判断是否需要重新同步
      console.log('第一次文件下载', data)
      // return
      // return this.entryUserInfo(); //测试
      if (data.msg == '第一次文件下载成功') {
        // this.entryUserInfo();
        this.yixia = true
        this.kai = false
        this.ban = false
        this.bai = false
        var that = this
        // this.baohannotices();
        // return  //测试

        setTimeout(function () {
          // 异步，延迟执行
          // that.$router.push({
          //   name: "defray",
          //   params: {
          //     // bao,
          //     // app,
          //   },
          // });
          ;(that.tongbu = '同步资料完成，跳转下载'), (that.tong = false)
          that.baohannotices()
        }, 1000)
      } else {
        // this.entryUserInfo(); //测试
      }
    },
    async bhtypelist() {
      var bbc = this.unify_code
      const { data } = await bhtypelist(bbc)
      this.cion = data.data
    },
    entryUserInfo() {
      this.quan = true
      var that = this
      this.active = 0
      // 进行三步走

      setTimeout(function () {
        // 异步，延迟执行
        // console.log(123);
        that.next(123)
      }, 4000)
    },
    // 三步
    async next(e) {
      var bbc = this.unify_code
      var cion = this.input.baohanno
      var app = this.applyno
      var that = this
      var tijiao = {
        applyno: app,
        type: 1
      }
      console.log('申请')

      const { data } = await filesign(tijiao) // 合成
      console.log(data)
      if (data.code == 200) {
        this.ban = false
        this.$message({
          showClose: true,
          message: data.msg,
          type: 'success'
        })
        setTimeout(() => {
          that.tongbu = '同步资料完成，跳转下载'
          that.tong = false
        }, 1000)
        this.yixia = true
        // this.$message.error(data.msg);
        // setTimeout(function () {
        //   // 异步，延迟执行
        //   that.bhfilezt2();
        // }, 1000);

        return
      } else {
        this.$message.error(data.msg)
        this.tongbu = '重新获取'
        this.qianyue = '出函失败'
        this.tong = false
      }
    },
    async bhfilezt() {
      var that = this
      var apps = this.applyno
      // var apps = "TBaoHanHNYZTBBH2022072717222050537";
      const { data } = await bhfileState(apps) //合同下载后判断是否成功

      if (data.msg == '第一次文件下载成功') {
        setTimeout(function () {
          ;(that.tongbu = '同步资料完成，跳转下载'), (that.tong = false)
        }, 1000)

        this.yixia = true
      } else {
        this.$notify({
          title: '提醒',
          message: '同步资料失败。'
        })
        this.tongbu = '重新获取'
        this.qianyue = '出函失败'
        this.tong = false
      }
    },
    async bhfilezt2() {
      var that = this
      var bbc = this.unify_code
      var cion = this.input.baohanno
      var app = this.applyno
      var tijiao = {
        applyno: app,
        type: 1
      }
      var that = this
      const { data } = await bhfiledown(tijiao) // 下载
      console.log(data)
      if (data.code == 200) {
        this.bai = false
        // this.$message.error(data.msg);
        this.$message({
          showClose: true,
          message: data.msg,
          type: 'success'
        })
        var bion = data.data + ''
        console.log('cun')
        setTimeout(function () {
          // 异步，延迟执行
          that.bhfilezt()
        }, 1000)

        return
      } else {
        this.$message.error(data.msg)
        this.tongbu = '重新获取'
        this.qianyue = '出函失败'
        this.tong = false
      }

      // sessionStorage.setItem(bion,value)
      // this.tongbu = "同步资料完成";
      return
    },
    async baohannotices() {
      const loading = this.$loading({
        lock: true,
        text: '',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // console.log(123);
      var that = this
      var bbc = this.applyno
      const { data } = await baohannotice(bbc)
      console.log('提交通知', data)

      this.codeion = data.code
      this.message = data.message
      var bao = this.input.baohanno
      var app = this.applyno
      // if(data=="系统测试"){
      //   return    setTimeout(function () {
      //     // 异步，延迟执行
      // this.$router.push({
      //   name: "defray",
      //   params: {
      //     bao,
      //     app,
      //   },
      // });
      // 异步，延迟执行
      //     that.tong = false;
      //   }, 1000);
      // }

      if (data.code == 1) {
        loading.close()
        // return //测试
        // setTimeout(function () {
        //   this.$router.push({
        //     name: "defray",
        //     params: {
        //       bao,
        //       app,
        //     },
        //   });
        //   异步，延迟执行
        //   that.tong = false;
        // }, 1000);
      } else {
        loading.close()
      }
      if (this.codeion == 0) {
        return this.$alert(this.message, '', {
          confirmButtonText: '确定',
          customClass: 'messageasd'
        })
      } else {
        //今天
        this.$router.push({
          name: 'defray',
          params: {
            bao,
            app
          }
        })
      }
      //显示同步按钮
      // console.log(data);
      // this.$notify({
      //   title: "提醒",
      //   message: "投保申请结果通知成功!",
      // });
    },

    project() {
      //  return //测试
      var bao = this.input.baohanno
      var app = this.applyno
      if (this.tongbu == '重新获取') {
        console.log(123)
        this.tong = true
        this.kai = true
        this.ban = true
        this.bai = true
        this.qianyue = '出函中'
        // this.tong = false;
        this.entryUserInfo()
      } else {
        this.baohannotices()

        //  this.$router.push({
        //     name: "defray",
        //     params: {
        //       bao,
        //       app,
        //     },
        //   });
        // return  //没有下一步  报错测试
        // console.log(this.codeion===0);
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    chengg(response, file, fileList) {
      this.input.zfimage = response.data.url
    }
  }
}
</script>

<style scoped>
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #2e8e9a;
  width: 120px;
  height: 120px;
  /* animation-name:load; */
  animation: load 3s linear infinite;
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
html {
  width: 100vw;
}
.asdzong {
  position: fixed;
  right: 20px;
  bottom: 10%;
  width: 200px;
  display: flex;
  border: 1px #c5c5c5 solid;
  border-top: 3px tomato solid;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-bottom: 20px;
}
.asdimgh {
  width: 60px;
  height: 60px;
}
.asdabutton {
  /* margin-top: 5rem; */
  width: 16%;
  /* position: fixed; */
  bottom: 20%;
  /* height: 5.5%; */
  padding: 0.3125rem 0;
  line-height: 1.875rem;
  border-radius: 0rem;
  background: #069cff;
  text-align: center;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
}
.quan {
  /* background: rgb(128, 128, 128, 1); */
  /* position: fixed;
  top: -160px;
  bottom: 0;
  left: 0;
  right: 0; */
  height: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cdion-2 {
  position: relative;
}
.cdion-3 {
  position: absolute;
  font-size: 12px;
  top: -25px;
  left: 9px;
}
.cion-fe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app {
  /* padding: 0 20%; */
}
/deep/ .el-steps--vertical {
  height: 80%;
}
.el-icon-loading {
  font-size: 1.25rem;
}
.cion-bu {
  margin-top: 5rem;
  height: 40px;
  font-size: 13px;
  line-height: 40px;
  width: 12.5rem;
  border-radius: 11px;
  background: #069cff;
  color: #fff;
}
.cion-bu1 {
  margin-top: 5rem;
  height: 40px;
  line-height: 40px;
  width: 12.5rem;
}
.felx {
  display: flex;
}
.felx-1 {
  padding: 0 0.3125rem;
  height: 5rem;
  display: flex;
  line-height: 8rem;
  font-size: 1.875rem;

  /* align-items:center; */
  color: rgb(170, 167, 167);
}
.felx-2 {
  padding: 0 0.3125rem;
  height: 5rem;
  display: flex;
  line-height: 8rem;
  font-size: 1.875rem;
  font-weight: 900;
  /* align-items:center; */
  color: #069cff;
}
.yanse {
  /* padding-left: 3px; */
  padding-top: 0.2rem;
  /* width: 1.5125rem; */
  height: 1.5125rem;
  /* color: #0a308f; */
}
.yanse1 {
  margin-top: 3px;
  width: 2.5125rem;
  height: 2.5125rem;
}
.cdion-txt {
  font-size: 1rem;
  margin-bottom: 20px;
}
.dion {
  line-height: 5.3125rem;
  font-size: 0.9375rem;
  padding: 0rem 5px;
}
.a-imgs {
  padding-top: 1.4rem;
  width: 1.5125rem;
  height: 1.5125rem;
}
.namei {
  margin-top: 0.625rem;
  height: 20%;
  margin-left: 70%;

  /* height: 100%;
    margin-left: 70%; */
}
/* /deep/ .el-step__icon.is-icon{
    border-radius: 50%;
    border: .125rem solid;
    border-color: inherit;
    w
} */
.app-xt {
  padding: 0 8%;
}
.cdion-1 {
  width: 3rem;
  height: 3rem;
  font-size: 31px;
  text-align: center;
  line-height: 3rem;
  color: #fff;
  background: #069cff;
  border: 2px solid #069cff;
  margin-bottom: 1.25rem;
  border-radius: 20rem;
  /* background-image:url("../../../src/assets/imges/1fff.png"); */
  /* background-size: 100%,100%; */
}
.cdion-2 {
  width: 3rem;
  height: 3rem;
  font-size: 31px;
  text-align: center;
  line-height: 3rem;
  border: 2px solid #f6f7f8;
  color: #fff;
  background: #f6f7f8;
  margin-bottom: 1.25rem;
  border-radius: 20rem;
}
.b-bbc {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.block {
  position: absolute;
  right: 100%;
}
.indec {
  display: inline-block;
  width: 26.25rem;
  padding: 3.125rem;
  margin-top: -15%;
  height: 35%;
  padding-bottom: 0.625rem;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 0.0625rem solid #ebeef5;
  font-size: 1.125rem;
  box-shadow: 0 0.125rem 0.75rem 0rgba (0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding-bottom: 1.875rem;

  /* display: flex;
  flex-direction: column;
    justify-content: space-between; */
}
.name {
  margin-bottom: 1.25rem;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name-jin {
  width: 100%;
  height: 100%;
  background: #dedede;
}
.uppder {
  padding-right: 0.75rem;
}
.input-flex {
  display: flex;
  margin-top: 0.625rem;
}
.input-1 {
  width: 12.5rem !important;
  width: 7.0625rem;
  /* height: 1.25rem !important; */
}
.app-h {
  padding-left: 27px;
  width: 100%;
  height: 43px;
  line-height: 43px;
  background: #1890ff;
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
/deep/ .el-input__inner {
  margin-left: 0.875rem !important;
  height: 1.6875rem !important;
}

.text {
  font-size: 0.875rem;
}

.item {
  padding: 0.5rem 0;
}

.box-card {
  width: 30rem;
}
.flex-txt {
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff0101;
}
.flex-txt1 {
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 6.25rem;
  text-align: right;
}
.abc {
  padding-left: 1.5rem;
}
.app-a {
  display: flex;
  /* margin-top: 28px; */
  position: relative;
}
.a-left {
  width: 50%;
  height: 47px;
  margin-top: 1px;
  display: flex;
  line-height: 50px;
  align-content: center;
  justify-content: center;
  color: #069cff !important;
  /* background: #cdebff; */
  border: 1px solid #069cff;
  /* border-right: 0; */
  /* border-radius: 9px 0px 0px 9px; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.sk-circle {
  margin: 100px auto;
  width: 140px;
  height: 140px;
  position: relative;
}
.sk-circle .sk-child {
  width: 140px;
  height: 140px;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 10%;
  height: 10%;
  background-color: #298cde;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.asdzongs {
  position: relative;
  width: 200px;
  background: #ffffff;
  display: flex;
  border: 1px #c5c5c5 solid;
  border-top: 3px tomato solid;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-bottom: 20px;
}
.a-img {
  padding-top: 13.6px;
  padding-right: 15px;
  width: 20px;
  height: 20px;
}
.aa-img {
  position: absolute;
  height: 3.06rem;
  margin-top: 1px;
  padding: 0;
  left: 45%;
}
.a-imgs {
}
.a-right {
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 50px;
  width: 50%;
  height: 49px;
  margin-top: 0.0625rem;

  background: #069cff;
  /* border-radius: 7px; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.app-b {
  font-size: 0.5625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 45px;
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding: 0 30%;
  /* align-content: space-around;
  justify-content: space-between; */
}
.app-c {
  width: 100%;
  margin-top: 30px;
}
.wohi-txt {
  font-size: 12px;
  padding-left: 12px;
}
.asimg {
  width: 360px;
  height: 210px;
}
.app-b2 {
  display: flex;
  line-height: 24px;
  font-size: 13px;
  width: 48%;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 26px;
}
.b-txt {
  width: 296px;
  height: 24px;
  flex: 1;
  background: #f3f3f3;
  border: 1px solid #dedede;
  padding-left: 6px;
  line-height: 24px;
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.addxin {
  position: absolute;
  top: 45%;
  left: 35.5%;
  font-size: 15px;
  transform: rotate(359deg);
}
.addxin1 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addxin2 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addxin3 {
  height: 165px;
  display: flex;
  flex-direction: column;
}
.addxin4 {
  display: flex;
  width: 100%;
  justify-content: center;
}
.c-img {
  width: 30%;
  height: 100%;
}
.c-txt {
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.txt-1 {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-2 {
  margin-top: 17px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-3 {
  margin-top: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-4 {
  margin-top: 15px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.c-div {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}
.butt {
  width: 100%;
  display: flex;
  justify-content: center;
}
.button-i {
  margin-top: 48px;
  width: 20%;
  height: 5.5%;
  padding: 5px 0;
  line-height: 30px;
  border-radius: 20px;
  background: #069cff;
  text-align: center;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
}
/* 上传 */
</style>
<style>
.messageasd {
  width: 300px !important;
  margin-top: -15.875rem;
}
.el-message-box__btns {
  padding: 29px 15px 0 !important;
  text-align: center !important;
}
/* .addxinss{
    position: absolute;
           top: 45%;
           left: 45.5%;
           font-size: 15px;
           transform: rotate(359deg);
} */
</style>
